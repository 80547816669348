<template>
  <div class="live_msg" v-if="msgBoxShow">
    <div class="empty" v-if="conversationList.length == 0">
      <img src="@/assets/img/home/empty.png" alt="" class="empty_img" />
      <div class="empty_text">暂无消息</div>
    </div>
    <van-list
      v-else
      class="msg_list"
      @load="onLoad"
      v-model:loading="loading"
      finished-text="没有更多了"
      :finished="isSyncCompleted"
    >
      <!-- 系统消息 -->
      <MsgItem
        v-for="(item, j) in systemMsgList"
        :key="j"
        :item="item"
        :type="1"
        @click="goDetail(1, item)"
      />
      <div v-for="(item, i) in conversationList" :key="i">
        <MsgItem class="living_msg_item" :item="item" :j="i" :type="2" @click="goDetail(item)" />
      </div>
    </van-list>
  </div>
  <div v-else style="height: 100%">
    <MsgDetails @goBack="goBack" :msgBoxShow="msgBoxShow" />
  </div>
</template>
<script setup name="liveMsg">
import TencentCloudChat from "@tencentcloud/chat";
import { chat } from "@/untils/tim";
import Bus from "@/untils/event-bus";
import MsgItem from "../../../message/components/msgItem.vue";
import MsgDetails from "./msgDetails.vue";
import { nextTick, onBeforeUnmount, onMounted, ref } from "vue";
const msgBoxShow = ref(true);
const conversationList = ref([]); //会话列表
const isSyncCompleted = ref(true); //会话列表是否拉完
const systemMsgList = ref([]); //系统消息
const loading = ref(true);
function onLoad() {
  if (!isSyncCompleted.value) {
    _getConversationList();
  }
}
function goDetail(item) {
  msgBoxShow.value = false;
  if (item.unreadCount > 0) {
    chat()
      .setMessageRead({
        conversationID: item.conversationID,
      })
      .then((res) => {
        nextTick(() => {
          item.unreadCount = 0;
        });
      });
  }
  localStorage.setItem("imChatType", item.type);
  if (item.type == "GROUP") {
    localStorage.setItem("imChatId", item.groupProfile.groupID);
  } else {
    localStorage.setItem("imChatId", item.userProfile.userID);
  }
}
function goBack() {
  msgBoxShow.value = true;
}
function _getConversationList() {
  // console.log("拉取会话列表");
  loading.value = true;
  //拉取会话列表
  chat()
    .getConversationList()
    .then((imResponse) => {
      console.log(imResponse.data);
      let arr = imResponse.data.conversationList.filter((item) => item.type != "@TIM#SYSTEM");
      let arr1 = arr.filter(
        (item) =>
          item.conversationID.indexOf("GUEST_pc_") < 0 &&
          item.conversationID.indexOf("GUEST_h5_") < 0 &&
          item.conversationID.indexOf("systemadmin") < 0
      );
      let arr2 = arr1.filter(
        (item) =>
          (item.type == "GROUP" && item.groupProfile.type != "AVChatRoom") ||
          (item.type == "C2C" && item.userProfile.userID != "admin")
      );
      let systemadminArr = imResponse.data.conversationList.filter(
        (item) => item.type == "C2C" && item.userProfile.userID == "systemadmin"
      );
      systemMsgList.value = systemadminArr;
      conversationList.value = arr2;
      isSyncCompleted.value = imResponse.data.isSyncCompleted;
      loading.value = false;
      console.log(arr2);
    })
    .catch(() => {
      loading.value = false;
    });
}
function onConversationListUpdated(event) {
  ///监听会话列表更新
  let arr = event.data.filter((item) => item.type != "@TIM#SYSTEM");
  let arr1 = arr.filter(
    (item) =>
      item.conversationID.indexOf("GUEST_pc_") < 0 &&
      item.conversationID.indexOf("GUEST_h5_") < 0 &&
      item.conversationID.indexOf("systemadmin") < 0
  );
  let arr2 = arr1.filter(
    (item) =>
      (item.type == "GROUP" && item.groupProfile.type != "AVChatRoom") ||
      (item.type == "C2C" && item.userProfile.userID != "admin")
  );
  let systemadminArr = event.data.filter(
    (item) => item.type == "C2C" && item.userProfile.userID == "systemadmin"
  );
  systemMsgList.value = systemadminArr;
  conversationList.value = arr2;
}
chat().on(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
onMounted(() => {
  Bus.$on("openLiveChat", () => {
    nextTick(() => {
      msgBoxShow.value = false;
    });
  });
});
onBeforeUnmount(() => {
  console.log("取消监听");
  chat().off(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
});
</script>
<style scoped>
.live_msg {
  overflow-y: scroll;
  height: 100%;
}
.empty {
  height: 100%;
  padding: 0 !important;
}
.live_msg::-webkit-scrollbar {
  display: none;
}
.msg_list {
  padding-bottom: 10vw;
}
.living_msg_item /deep/ .avart {
  width: 48px;
  height: 48px;
}
.living_msg_item /deep/ .avart img {
  width: 48px;
  height: 48px;
}
.living_msg_item /deep/ .name {
  font-size: 4vw !important;
}
</style>
